jQuery(document).ready( function($) {
	var halfHeight_int = $(window).height()/2;
/*  $(function () {
     $('.pages_nav').toggle(function () {	  	
	   $('#page_nav').css('visibility','visible');
	   $('#page_nav').css('left','-100%');
	   $('#page_nav').css('width','100%');
       $('#page_nav').animate({left:'40px'}, {queue: false, duration: 500});
	   
       $('#page_nav').animate({top: halfHeight_int - $('#page_nav').height()/2 },0) 	   	 
      }, function () {
       $('#page_nav').animate({left:'-100%'}, {queue: false, duration: 500});
	   
       $('#page_nav').animate({top: halfHeight_int - $('#page_nav').height()/2 },0) 	   	 	   
    });
	  
		$('#toggle_page_nav').css('top', halfHeight_int - $('.pages_nav').height()/2)  
 
  })
	$( window ).resize(function() {
		halfHeight_int = $(window).height()/2
		$('#toggle_page_nav').css('top', halfHeight_int - $('.pages_nav').height()/2)  ;
		$('#page_nav').css('top', 0) ;
	});  ;*/
  if( $('#ele_form').length != 0 ){
	$('#ele_form .ele_checkbox').click(
		function(){			
			if ($(this).prop('checked'))  {	
			
				$('#ele_hide_form #h_' + $(this).attr('id') ).prop('checked', false);
				
			} else {
							
				$('#ele_hide_form #h_' + $(this).attr('id') ).prop('checked', true);
			}
			$('#ele_hide_form').submit();
		}
	)	
  }

/*	window.addEventListener('resize', function(e) {
		var video_div = document.getElementsByClassName('video');
		video_div.style.height = video_div.offsetWidth * 0.5625;
	}); */ 
}) 

function $_GET(q,s) {
	s = s ? s : window.location.search;
	var re = new RegExp('&'+q+'(?:=([^&]*))?(?=&|$)','i');
	return (s=s.replace(/^\?/,'&').match(re)) ? (typeof s[1] == 'undefined' ? '' : decodeURIComponent(s[1])) : undefined;
} 